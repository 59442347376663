import React from 'react';
import Nav from '../../common/Nav';
import Footer from '../../common/Footer';

const AboutUs = () => {
    return (
        <div className="bg-gray-100 ">
            
            <div>
                <div className="relative w-full h-screen overflow-hidden">
                
                    {/* Video */}
                    <video
                        className=" absolute top-0 left-0 w-full h-full object-cover"
                        autoPlay
                        loop
                        muted
                        
                        
                    >
                        <source src="https://cdn.pixabay.com/video/2020/12/15/59291-492700392_large.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    
                    {/* <div className='p-6'>
                <Nav />
            </div> */}
                    {/* Text Overlay */}
                    <div className="absolute bg-transparent inset-0  bg-black bg-opacity-10">
                        {/* <h2 className="text-white text-2xl md:text-4xl font-bold font-sans">
                            About Pravasa.IN
                        </h2> */}
                        <div className='p-6'>
                <Nav />
            </div>
                        <div className='basis-4/6  mt-72  items-center justify-center p-12'>
                        <h1 className="text-3xl md:text-7xl text-center font-bold text-gray-200">About Pravasa.IN</h1>
                        <h4 className='text-xl md:text-4xl text-center font-semibold text-gray-300'>Welcome to Pravasa!</h4>
                        <p className='text-gray-200 md:text-xl'>At Pravasa, we believe that travel is not just about reaching a destination; it’s about creating unforgettable experiences and making lasting memories. With over 10 years of expertise in the travel industry, our dedicated team is passionate about helping you explore the world, one adventure at a time.</p>
                    </div>
                    </div>
                </div>
            </div>
            <div className="  mx-auto px-4 pt-10 ">
                {/* <div className='bg-white flex flex-col md:flex-row items-center gap-4 py-10 h-full w-full shadow-lg'>
                    <div className='basis-4/6  p-12'>
                        <h1 className="text-3xl md:text-4xl text-center font-bold text-gray-800">About Us</h1>
                        <h4 className='text-xl md:text-2xl text-center font-semibold text-gray-800'>Welcome to Pravasa!</h4>
                        <p>At Pravasa, we believe that travel is not just about reaching a destination; it’s about creating unforgettable experiences and making lasting memories. With over 10 years of expertise in the travel industry, our dedicated team is passionate about helping you explore the world, one adventure at a time.</p>
                    </div>
                    <div className=' h-full w-4/5 md:h-96 md:w-80  '>
                        <img className='w-full h-full' src='https://i.pinimg.com/736x/fc/41/7c/fc417cd181dc725817efe6c27871173a.jpg'></img>
                    </div>
                </div> */}
                {/* Hero Section */}
                {/* <div className="text-center mb-12">
                    <h1 className="text-3xl md:text-4xl font-bold text-gray-800">About Us</h1>
                    <p className="mt-4 text-gray-600">
                        Discover your next adventure with us! We are committed to providing unforgettable travel experiences.
                    </p>
                </div> */}

                {/* Mission Section */}
                {/* <div className='  py-20 '>
                    <div className='flex flex-col md:flex-row justify-center items-center  gap-4 pb-20 h-full md:h-96 w-full'>
                        <div className="mb-10 basis-1/2">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Mission</h2>
                            <p className="text-gray-600">
                                Our mission is to inspire and empower travelers by providing exceptional service, expert guidance, and personalized itineraries tailored to your unique preferences. Whether you're dreaming of a relaxing beach getaway, an adventurous mountain trek, or a cultural journey through vibrant cities, we are here to make your travel dreams a reality.
                            </p>
                        </div>
                        <div className='h-full w-4/5 md:h-96 md:w-96	'>
                            <img className='w-full h-full' src='https://i.pinimg.com/236x/05/b1/0b/05b10bc874f4fd995da171ce3a5be09f.jpg'></img>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-center items-center gap-4 pt-20 h-96 w-full'>
                        <div className='h-full w-4/5 md:h-96	md:w-96	 '>
                            <img className='w-full h-full' src='https://i.pinimg.com/236x/05/b1/0b/05b10bc874f4fd995da171ce3a5be09f.jpg'></img>
                        </div>
                        <div className="mb-10 basis-1/2">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Join Us on Your Next Adventure!</h2>
                            <p className="text-gray-600">
                                Whether you’re a seasoned traveler or planning your first trip, [Your Travel Agency Name] is here to guide you every step of the way. Let us help you discover new horizons and experience the beauty of the world.

                            </p>
                        </div>

                    </div>
                </div> */}

<section className='bg-box1  flex flex-col lg:flex-row rounded-xl'>
            <div className='xs:flex xs:justify-center   md:p-32 md:pt-80'>
              <div className='flex flex-col justify-center items-center   rounded-full w-60 h-60 md:w-96 md:h-96 box '>
                <div className=' bg-gray-100 flex flex-col justify-center items-center rounded-full w-52 h-52  md:w-80 md:h-80  '>
                  <h1 className='text-gray-900 font-bold text-center font-sans'> OUR<br /> MISSION</h1>
                  <h5>INFOGRAPHIC</h5>
                </div>
              </div>
            </div>
            <div className=''>
              <div>
                <div>

                </div>
                <div className='relative bg-gray-100   lg:m-8  md:mx-8 flex flex-col-2 rounded-xl md:rounded-full w-full md:w-5/6 lg:w-5/6'>
                  <div className=' bg-box1 relative  pr-6 pt-12 md:pt-0 md:pr-24 left-0 border-y-2 border-l-2 md:border-y-8 md:border-l-8 border-gray-100 '>
                    <p className=' text-2xl md:text-4xl font-bold  pl-12 md:pl-32 md:pt-8  '> 01</p>
                    <div className=' absolute rounded-full  h-60  md:h-24 w-24 -skew-x-[0deg] md:w-60 flex items-center justify-center mx-0 mt-0 md:mx-2 md:mt-4  box '>
                      <p className='text-sky-400 text-4xl rotate-[270deg] md:rotate-[0deg] md:text-4xl font-bold font-sans  '  >Mission</p>
                    </div>
                  </div>

                  <div className='w-full md:w-4/6 p-4 '>
                    {/* <p className='font-normal text-lg '>INFOGRAPHIC</p> */}
                    <p className='font-bold text-2xl'> Pravasa.IN's Mission </p>
                    <p className='font-normal text-md'>At Pravasa.IN, our mission is to inspire and create unforgettable travel experiences tailored to every explorer's dreams. We are committed to providing seamless, personalized, and enriching journeys that connect people with the world’s most breathtaking destinations.

With a focus on exceptional service, sustainability, and authentic cultural experiences, we strive to turn every trip into a cherished memory. Whether it’s a relaxing getaway, an adventurous expedition, or a luxurious retreat, we ensure stress-free planning and expert guidance at every step.

Let us take you on a journey where every moment is filled with discovery, wonder, and joy</p>
                  </div>

                </div>
              </div>
              <div>
                <div>

                </div>
                <div className='relative bg-gray-100   md:m-8 flex flex-col-2 rounded-xl md:rounded-full w-full md:w-5/6 lg:w-5/6'>
                  <div className=' bg-box1 relative  pr-6 pt-12 md:pt-0 md:pr-24 left-0 border-y-2 border-l-2 md:border-y-8 md:border-l-8 border-gray-100 '>
                    <p className=' text-2xl md:text-4xl font-bold  pl-12 md:pl-32 md:pt-8  '> 02</p>
                    <div className=' absolute rounded-full  h-60  md:h-24 w-24 -skew-x-[0deg] md:w-60 flex items-center justify-center mx-0 mt-0 md:mx-2 md:mt-4  box '>
                      <p className='text-rose-400 text-4xl rotate-[270deg] md:rotate-[0deg] md:text-4xl font-bold font-sans  '  >Vision</p>
                    </div>
                  </div>

                  <div className='w-full md:w-4/6 p-4 '>
                    {/* <p className='font-normal text-lg '>INFOGRAPHIC</p> */}
                    <p className='font-bold text-2xl'> Pravasa.IN's Vision </p>
                    <p className='font-normal text-md'>At Pravasa.IN, we envision a world where travel is more than just a journey—it’s a transformative experience that connects people, cultures, and dreams. We aspire to be a leading travel partner, recognized for our commitment to excellence, innovation, and responsible tourism.

Our goal is to make travel seamless, sustainable, and enriching for all, ensuring that every traveler experiences the beauty of the world while preserving it for future generations. Through personalized service, cutting-edge technology, and a passion for exploration, we aim to redefine the way people discover the world.

Let’s create a future where every journey inspires, empowers, and brings people closer together.</p>
                  </div>

                </div>
              </div>
              <div>
                <div>

                </div>
                <div className='relative bg-gray-100   md:m-8 flex flex-col-2 rounded-xl md:rounded-full w-full md:w-5/6 lg:w-5/6'>
                  <div className=' bg-box1 relative  pr-6 pt-12 md:pt-0 md:pr-24 left-0 border-y-2 border-l-2 md:border-y-8 md:border-l-8 border-gray-100 '>
                    <p className=' text-2xl md:text-4xl font-bold  pl-12 md:pl-32 md:pt-8  '> 03</p>
                    <div className=' absolute rounded-full  h-60  md:h-24 w-24 -skew-x-[0deg] md:w-60 flex items-center justify-center mx-0 mt-0 md:mx-2 md:mt-4  box '>
                      <p className='text-orange-400 text-4xl rotate-[270deg] md:rotate-[0deg] md:text-4xl font-bold font-sans  '  >Value</p>
                    </div>
                  </div>

                  <div className='w-full md:w-4/6 p-4 '>
                    {/* <p className='font-normal text-lg '>INFOGRAPHIC</p> */}
                    <p className='font-bold text-2xl'> Values of Pravasa.IN </p>
                    <p className='font-normal text-md'>🌍 Passion for Travel – Inspiring meaningful adventures.<br/>
🤝 Customer First – Personalized, seamless experiences.<br/>
🌿 Sustainability – Promoting responsible tourism.<br/>
✨ Integrity – Honesty, transparency, and trust.<br/>
🚀 Innovation – Embracing new solutions for excellence.<br/>
🌏 Cultural Appreciation – Celebrating diversity and authentic experiences.<br/>

We make every journey unforgettable!</p>
                  </div>

                </div>
              </div>
            </div>

          </section>
                {/* Team Section */}
                <div className="mb-10 mt-24">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Meet Our Team</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {teamMembers.map((member, index) => (
                            <div key={index} className="bg-white p-6 rounded-lg shadow-lg text-center">
                                <img src='https://i.pinimg.com/736x/76/f3/f3/76f3f3007969fd3b6db21c744e1ef289.jpg' alt={member.name} className="w-24 h-24 mx-auto rounded-full mb-4" />
                                <h3 className="font-semibold text-lg text-gray-800">{member.name}</h3>
                                <p className="text-gray-500">{member.position}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Testimonials Section */}
                <div className=' pb-4 w-full'>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">What Our Clients Say</h2>
                    <div className="space-y-4">
                        {testimonials.map((testimonial, index) => (
                            <blockquote key={index} className="bg-white p-6 rounded-lg shadow-lg">
                                <p className="text-gray-600 italic">"{testimonial.quote}"</p>
                                <footer className="mt-2 w-full text-gray-800 font-semibold">
                                    — {testimonial.author}
                                </footer>
                            </blockquote>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

const teamMembers = [
    {
        name: 'Aman Saini',
        position: 'Travel Consultant',
        image: 'https://via.placeholder.com/150',
    },
    {
        name: 'Aparna R.',
        position: 'Adventure Specialist',
        image: 'https://via.placeholder.com/150',
    },
    {
        name: 'C. K. Murti',
        position: 'Customer Service Manager',
        image: 'https://via.placeholder.com/150',
    },
];

const testimonials = [
    {
        quote: 'The trip was amazing! Everything was perfectly planned.',
        author: 'Jigar Dube',
    },
    {
        quote: 'I had the best experience with this travel agency. Highly recommended!',
        author: 'Sneha Gupta',
    },
    {
        quote: 'Great service and very knowledgeable staff.',
        author: 'Mira Singh',
    },
];

export default AboutUs;
